<template>
  <div class="grid">
    <div class="xl:col-3 lg:col-4 col-12" v-if="fromHomePage !== true">
      <as-title :title="$t('share_accident.filtre_title')" :size="2"></as-title>
      <br />
      <br />
      <form id="filter-form" @submit.prevent="() => { }">
        <div class="p-fluid grid">
          <div class="field col-12">
            <div class="p-float-label">
              <Dropdown v-model="state.type" :options="accidentTypes" @update:modelValue="applyFilters()"
                optionLabel="label" optionValue="value" />
              <label>{{ $t("declaration_accident_form.accident_type") }}</label>
            </div>
          </div>
          <div class="field col-12" v-if="!isEntrepriseBusy">
            <div class="p-float-label">
              <Dropdown v-model="state.selectedEntreprise" :options="entreprisesOptions"
                @update:modelValue="applyFilters()" optionLabel="label" optionValue="value">
              </Dropdown>
              <label>{{ $t("accident_infos_modal.company_label") }}</label>
            </div>
          </div>
          <div class="field col-12">
            <div>
              <Checkbox v-model="state.highSeverityPotential" binary @update:modelValue="applyFilters()"
                inputId="hight-severity-checkbox" />
              <label class="mt-2 ml-2" for="hight-severity-checkbox">
                {{ $t("share_accident.high_severity_label") }}
              </label>
            </div>
          </div>
          <div class="field col-12">
            <div class="p-float-label">
              <Dropdown v-model="state.lieu" :options="lieux" @update:modelValue="applyFilters('lieu')"
                optionLabel="label" />
              <label>{{ $t("declaration_accident_form.location_field") }}</label>
            </div>
          </div>
          <div class="field col-12">
            <div class="p-float-label">
              <Dropdown v-model="state.categoriePersonne" :options="categoriesPersonne"
                @update:modelValue="applyFilters('categoriePersonne')" optionLabel="label" />
              <label>{{ $t("declaration_accident_form.category_field") }}</label>
            </div>
          </div>
          <div class="field col-12">
            <div class="p-float-label">
              <Dropdown v-model="state.zoneBlessures" :options="zonesBlessures"
                @update:modelValue="applyFilters('zoneBlessures')" optionLabel="label" />
              <label>{{ $t("declaration_accident_form.injury_area_field") }}</label>
            </div>
          </div>
          <div class="field col-12">
            <div class="p-float-label">
              <Dropdown v-model="state.natureBlessures" :options="naturesBlessures"
                @update:modelValue="applyFilters('natureBlessures')" optionLabel="label" />
              <label>{{ $t("declaration_accident_form.nature_injury_field") }}</label>
            </div>
          </div>
        </div>
      </form>
      <Button :label="$t('share_accident.reset_filter_button_label')" class="w-full button-default"
        @click="resetFilter()" />
    </div>
    <div v-bind:class="[fromHomePage !== true ? 'xl:col-9 lg:col-8 col-12' : 'col-12']">
      <Paginator v-if="fromHomePage !== true" :first="currentPage * numberPerPage" :rows="numberPerPage"
        :totalRecords="filteredAccidents.length" @page="onChangePage($event)" :rowsPerPageOptions="[6, 12, 30, 60]" />
      <div id="list-accidents" class="grid">
        <div class="p-5 w-100 text-center" v-if="!filteredAccidents.length">
          <as-title :title="$t('share_accident.no_accident_with_filter_text')" :size="1"></as-title>
        </div>
        <Card v-for="(accident, index) of paginatedAccidents" :key="index">
          <template #header>
            <div class="card-img-container flex" @click="onSelectAccident(accident)">
              <ProgressSpinner class="m-auto" v-if="accidentsImg[accident.id] === 'loading'" />
              <template v-else>
                <img v-if="accident.image && accidentsImg[accident.id]" :src="accidentsImg[accident.id]"
                  class="card-img" />
                <img v-else :src="defaultImage" class="card-img" />
              </template>
              <span class="badge badge-high-severity p-2" v-if="accident.declarationAccident?.highSeverityPotential">
                {{ $t('share_accident.high_severity_label') }}
              </span>
            </div>
            <div class="card-title-container p-2 bg-primary">
              <as-title :title="getDetailsFromLocale(accident.details, 'titre')" :size="5" />
            </div>
          </template>
          <template #content>
            <div class="card-content-badges-container">
              <span class="badge bg-default date p-2">
                {{ accident.date ? $d(accident.date, "day") : "?" }}
                {{ accident.date ? $d(accident.date, "month") : "" }}
                {{ accident.date ? $d(accident.date, "year") : "" }}
              </span>

              <span class="badge p-2 ml-auto"
                :style="{ backgroundColor: getAccidentTypeColor(accident.declarationAccident?.type), 'color': 'white' }">
                {{ getAccidentTypeReducedabel(accident.declarationAccident?.type) }}
              </span>
            </div>
            <p class="card-text text-justify mt-3">
              {{ getDetailsFromLocale(accident.details, "description") }}
            </p>
          </template>
          <template #footer>
            <Button :label="$t('share_accident.card_button_label')" class="w-full button-default"
              @click="onSelectAccident(accident)" />
          </template>
        </Card>
      </div>
      <Paginator v-if="filteredAccidents.length && fromHomePage !== true" :first="currentPage * numberPerPage"
        :rows="numberPerPage" :totalRecords="filteredAccidents.length" @page="onChangePage($event)"
        :rowsPerPageOptions="[6, 12, 30, 60]" />
    </div>
  </div>
</template>

<script>

import Button from "primevue/button";
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Dropdown from "primevue/dropdown";
import Paginator from "primevue/paginator";
import ProgressSpinner from "primevue/progressspinner";

import AsTitle from "../Elements/AsTitle";
import { getAccidentTypeLabel, getAccidentTypeReducedabel, getAccidentTypeColor, bufferToBlobUrl } from "../../util";
import entreprisesServiceApi from "../../services/entreprisesServiceApi";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import useVuelidate from "@vuelidate/core";
export default {
  name: "ListAccident",
  setup() {
    const state = {
      type: -1,
      selectedEntreprise: -1,
      highSeverityPotential: false,
      lieu: null,
      categoriePersonne: null,
      zoneBlessures: null,
      natureBlessures: null,
    };

    const rules = {
      type: {},
      selectedEntreprise: {},
      highSeverityPotential: {},
      lieu: {},
      categoriePersonne: {},
      zoneBlessures: {},
      natureBlessures: {},
    };

    const v$ = useVuelidate(rules, state);
    return { v$, state };
  },
  components: {
    Button,
    Card,
    Checkbox,
    Dropdown,
    Paginator,
    ProgressSpinner,
    AsTitle,
  },
  props: {
    accidents: Array,
    fromHomePage: Boolean
  },
  beforeMount() {
    this.isEntrepriseBusy = true;
    entreprisesServiceApi.getEntreprises().then((results) => {
      this.entreprises = results;
      this.isEntrepriseBusy = false;
      this.updateFilterOptions();
    });
  },
  data() {
    return {
      defaultImage: require("../../assets/images/accident.jpeg"),
      currentPage: 0,
      numberPerPage: 6,
      totalEvents: 0,
      isEntrepriseBusy: true,
      entreprises: [],
      filteredAccidents: [...this.accidents],
      accidentsImg: {},
      filterFieldSelected: [],
      filterFieldAccidents: {},
      filterFieldPreviousState: {},
    };
  },
  watch: {
    accidents: {
      handler() {
        this.applyFilters();
      },
      deep: true,
    },
  },
  emits: ["onSelectAccident"],
  mixins: [fichiersServiceApi],
  methods: {
    onSelectAccident(accident) {
      this.$emit("onSelectAccident", accident);
    },
    async loadImage(accident) {
      if (accident.image && accident.image.externalId !== null) {
        if (!this.accidentsImg[accident.id]) {
          try {
            this.accidentsImg[accident.id] = 'loading'
            const fichier = await this.downloadFile(accident.image.externalId);
            this.accidentsImg[accident.id] = bufferToBlobUrl(fichier);
          } catch (error) {
            console.log(error)
            this.accidentsImg[accident.id] = null
          }
        }
      }
    },
    getDetailsFromLocale(details, key) {
      const detail = details?.find(
        (d) => d.langue.codeIso === this.$i18n.locale
      );
      return detail ? detail[key] : "";
    },
    getAccidentTypeLabel(number) {
      return getAccidentTypeLabel(number, this.$t)
    },
    getAccidentTypeReducedabel(number) {
      return getAccidentTypeReducedabel(number)
    },
    getAccidentTypeColor(number) {
      return getAccidentTypeColor(number, this.$t)
    },
    onChangePage(event) {
      this.numberPerPage = event.rows
      this.currentPage = event.page;
    },
    applyFilters(triggeredFilter = "") {

      if (triggeredFilter !== "") {
        delete this.filterFieldPreviousState[triggeredFilter]
        if (!this.filterFieldSelected.includes(triggeredFilter)) {
          this.filterFieldSelected.push(triggeredFilter)
        }
      }

      this.filterFieldSelected.forEach(nextFilter => {
        this.filterFieldPreviousState[nextFilter] = this.state[nextFilter]
      })

      const step1Filters = {
        "type": this.state.type,
        "idEntreprise": this.state.selectedEntreprise,
        "highSeverityPotential": this.state.highSeverityPotential ? true : null,
      }

      const step1FilteredAccidents = this.accidents.filter((accident) => {
        return Object.entries(step1Filters).every(([key, value]) => {
          if (value == null || value == -1) return true;
          if (key !== 'idEntreprise' && accident.declarationAccident === null) { return false; }
          return (key === 'idEntreprise' ? accident[key] : accident.declarationAccident[key]) === value;
        });
      });

      if (!step1FilteredAccidents.length) {
        this.filteredAccidents = []
        this.resetStep2FilterState()
        this.updateFilterOptions();
        return
      }

      const step2Filters = {
        "lieu": this.state.lieu?.value,
        "categoriePersonne": this.state.categoriePersonne?.value,
        "zoneBlessures": this.state.zoneBlessures?.value,
        "natureBlessures": this.state.natureBlessures?.value,
      };

      const step2FilteredAccidentsByStep = []
      this.filterFieldSelected.forEach((filter, index) => {
        const previousFilteredAccidents = index === 0 ? step1FilteredAccidents : step2FilteredAccidentsByStep[index - 1]
        this.filterFieldAccidents[filter] = previousFilteredAccidents
        const currentfilteredAccidents = previousFilteredAccidents.filter((accident) => {
          const value = step2Filters[filter]
          if (value == null) return true;
          if (accident.declarationAccident === null) { return false; }
          return accident.declarationAccident[filter] === value;
        });

        step2FilteredAccidentsByStep.push(currentfilteredAccidents)
      })

      const populatedStep2FilteredAccidentsByStep = step2FilteredAccidentsByStep.filter(e => e.length)
      this.filteredAccidents = populatedStep2FilteredAccidentsByStep.length ?
        populatedStep2FilteredAccidentsByStep.pop() :
        step1FilteredAccidents

      this.updateFilterOptions();
    },
    updateFilterOptions() {
      this.lieux = this.fetchFilterAvailableValues('lieu', 'locations')
      this.categoriesPersonne = this.fetchFilterAvailableValues('categoriePersonne', 'categoryPersons')
      this.zonesBlessures = this.fetchFilterAvailableValues('zoneBlessures', 'woundsLocations')
      this.naturesBlessures = this.fetchFilterAvailableValues('natureBlessures', 'woundsTypes')
    },
    fetchFilterAvailableValues(key, translateKey) {
      const options = [...new Set(
        (
          this.filterFieldAccidents[key] ?
            this.filterFieldAccidents[key] :
            this.filteredAccidents
        ).map(accident => {
          return accident.declarationAccident?.[key];
        })
      )]
        .filter(Boolean)
        .map(value => ({ label: this.$t(`${translateKey}.${value}`), value }))

      if (this.filterFieldPreviousState[key]) {
        const previousValue = { ...this.filterFieldPreviousState[key] }.value
        const indexOf = options.findIndex(e => e.value === previousValue)
        this.state[key] = indexOf >= 0 ? options[indexOf] : null
      }

      return options
    },
    resetFilter() {
      this.state = {
        type: -1,
        selectedEntreprise: -1,
        highSeverityPotential: false,
      }
      this.resetStep2FilterState()
      this.applyFilters()
    },
    resetStep2FilterState() {
      this.state.lieu = null
      this.state.categoriePersonne = null
      this.state.zoneBlessures = null
      this.state.natureBlessures = null
      this.filterFieldSelected = []
      this.filterFieldAccidents = {}
      this.filterFieldPreviousState = {}
    }
  },
  computed: {
    paginatedAccidents() {
      const start = this.currentPage * this.numberPerPage;
      const end = start + this.numberPerPage;
      const paginatedAccidents = this.filteredAccidents.slice(start, end);
      paginatedAccidents.forEach(e => this.loadImage(e));
      return paginatedAccidents
    },
    entreprisesOptions() {
      const enterprises = this.entreprises.map((el) => {
        return {
          label: `${el.nom}`,
          value: el.id,
        };
      });
      enterprises.unshift({ label: this.$t("share_accident.all_enterprise_filter_label"), value: -1 })
      return enterprises
    },
    accidentTypes() {
      const result = Array.from(Array(6).keys()).map(i => {
        return { label: this.getAccidentTypeLabel(i + 1), value: i + 1 }
      })
      result.unshift({ label: this.$t("share_accident.all_accident_filter_label"), value: -1 })
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";

#list-accidents {
  .p-card {
    flex: none;
    width: calc(100% / 3 - 20px);
    margin: 10px;
    display: flex;
    flex-direction: column;

    .p-card-header {
      .card-img-container {
        height: 250px;
        width: 100%;
        position: relative;

        &:hover {
          cursor: pointer;
        }

        .badge-high-severity {
          background-color: #bf2d0f;
          color: white;
          position: absolute;
          top: 10px;
          right: 10px;
          border: 2px solid #ffffff;
        }

        .card-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          display: block;
        }
      }

      .card-title-container {
        height: 74px;
        display: flex;
        align-items: center;

        h5 {
          color: white !important;
        }
      }
    }

    .p-card-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 275px;
      max-height: 275px;

      .p-card-content {
        padding: 0;
        overflow: hidden;

        .card-content-badges-container {
          display: flex;

          .badge.date {
            font-weight: bold;
          }
        }

        .card-text {
          line-height: 1.4;
        }
      }

      .p-card-footer {
        margin-top: auto;
      }
    }
  }

  .badge {
    border-radius: 0.375rem;
  }
}

@media screen and (max-width: 1200px) {
  #list-accidents {
    .p-card {
      width: calc(100% / 2 - 20px);
    }
  }
}

@media screen and (max-width: 550px) {
  #list-accidents {
    .p-card {
      width: calc(100% - 20px);
    }
  }

  .p-paginator-pages {
    display: none !important;
  }
}
</style>
