<template>
  <div id="home">
    <presentation-block />
    <as-container class="bg-radial">
      <objectives-block />
    </as-container>
    <as-container>
      <as-quote><span v-html="$t('home.quote')" /></as-quote>
    </as-container>
    <as-container v-if="(isSafetyLeader || isAdmin) && threeLastAccidents" class="bg-radial">
      <as-title :title="$t('home.last_accident_title')" :size="1" style="text-align: center"
        class="w-100 color-white"></as-title>
      <as-block :shadow="true" class="mt-6 pt-6" gutters="20px">
        <list-accidents :accidents="threeLastAccidents" :fromHomePage="true"
          @onSelectAccident="displayAccidentInfoModal" />
      </as-block>
    </as-container>
  </div>
  <as-modal :isVisible="showValidateDeclarationModal" class="validate-declaration-modal">
    <validate-declaration-form v-if="declarationToValidate" :accidentToEdit="accidentToEdit"
      :declaration="declarationToValidate" :isClosed="showValidateDeclarationModal" :isSending="isSending"
      @onClose="onHideValidateDeclarationModal" @onSubmit="validateDeclaration" />
  </as-modal>
  <as-modal :isVisible="showAccidentInfoModal" isCloseable @onClose="
    showAccidentInfoModal = false;
  accidentInfo = null;
  ">
    <accident-block v-if="accidentInfo" :accidentData="accidentInfo" :editAccidentBusy="editAccidentBusy"
      @editAccident="editAccident($event)" :showPdfButton="true" />
  </as-modal>
</template>

<script>
// @ is an alias to /src
import AsContainer from "@/components/Containers/AsContainer.vue";
import AsBlock from "@/components/Containers/AsBlock.vue";
import AsQuote from "@/components/Elements/AsQuote.vue";
import PresentationBlock from "@/components/Home/PresentationBlock.vue";
import ObjectivesBlock from "@/components/Home/ObjectivesBlock.vue";
import AccidentBlock from "../components/ShareAccident/AccidentBlock.vue";
import ListAccidents from "../components/ShareAccident/ListAccidents";
import AsTitle from "../components/Elements/AsTitle.vue";
import Carousel from "primevue/carousel";
import AsModal from "@/components/Modals/AsModal.vue";
import ValidateDeclarationForm from "../components/Forms/ValidateDeclarationForm";

import accidentsServiceApi from "../services/accidentsServiceApi";
import localeChangeDetectionMixin from "../mixins/localeChangeDetectionMixins";
import roleMixins from "@/mixins/roleMixins";
import apiEventTypes from "@/constantes/apiEventTypes";
import fichiersServiceApi from "../services/fichiersServiceApi";
import { mapGetters } from "vuex";
import { mapAccidentData } from "../util";


export default {
  name: "Home",
  components: {
    AsContainer,
    AsBlock,
    AsQuote,
    PresentationBlock,
    ObjectivesBlock,
    AsTitle,
    Carousel,
    AccidentBlock,
    ListAccidents,
    AsModal,
    ValidateDeclarationForm,
  },
  data() {
    return {
      lastAccident: undefined,
      isLastAccidentBusy: false,
      threeLastAccidents: [],
      isSending: false,
      accidentToEdit: undefined,
      declarationToValidate: undefined,
      editAccidentBusy: false,
      showValidateDeclarationModal: false,
      accidentInfo: undefined,
      showAccidentInfoModal: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  mixins: [localeChangeDetectionMixin, roleMixins, fichiersServiceApi],
  beforeMount() {
    this.getLastAccident();
  },
  methods: {
    getLastAccident() {
      this.isLastAccidentBusy = true;
      accidentsServiceApi
        .getLastAccidentFromNumber(3)
        .then((response) => {
          this.threeLastAccidents = response;
          this.isLastAccidentBusy = false;
        })
        .catch(() => {
          this.isLastAccidentBusy = false;
        });
    },
    getAccidentById(id) {
      this.editAccidentBusy = true;
      accidentsServiceApi
        .getAccidentById(id)
        .then((response) => {
          this.editAccidentBusy = false;
          this.showValidateDeclarationModal = true;
          this.accidentToEdit = response;
          this.declarationToValidate = this.accidentToEdit.declarationAccident;
        })
        .catch(() => { });
    },
    editAccident(accident) {
      this.getAccidentById(accident.id);
    },
    onHideValidateDeclarationModal() {
      this.declarationToValidate = undefined;
      this.accidentToEdit = undefined;
      this.showValidateDeclarationModal = false;
    },
    async validateDeclaration(accident) {
      if (this.isAdmin) {
        if (this.accidentToEdit) {
          accident.id = this.accidentToEdit.id;
        }

        accidentsServiceApi
          .saveAccident(accident, this.declarationToValidate.id)
          .then((savedAccident) => {
            this._uploadShareAccidentFiles(accident, savedAccident.id)
            this.accidentToEdit = undefined;
            this.declarationToValidate = undefined;
            this.showValidateDeclarationModal = false;

            this.getLastAccident();
            this.isSending = false;
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_SUCCESS, {
                detail: {
                  title: this.$t("popup_messages.success_title"),
                  message: this.$t("popup_messages.success_message"),
                },
              })
            );
          })
          .catch(() => {
            this.isSending = false;
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.ERROR, {
                detail: {
                  title: this.$t("popup_messages.default_error_title"),
                  message: this.$t("popup_messages.default_error_message"),
                },
              })
            );
          });
      }
    },
    displayAccidentInfoModal(accident) {
      this.accidentInfo = mapAccidentData(accident, this.$i18n.locale);
      this.showAccidentInfoModal = true;
    },

    async _uploadShareAccidentFiles(el, entityId, isDeclaration = false) {
      const files = this._getFilesToUpload(el, isDeclaration)
      this.uploadFiles(files, isDeclaration ? "declaration-accident" : "accident-detail", entityId, +this.user.idLdap, () => {
        this.getLastAccident()
      })
    },

    _getFilesToUpload(el, isDeclaration) {
      if (isDeclaration) { return el.filesToUpload }

      const newFiles = el.details.filter(e => e.filesToUpload.length).map(e => {
        const files = e.filesToUpload
        files.forEach(file => {
          file['addtionnalData'] = {
            type: "accident-files",
            idLangue: e.idLangue
          }
        })
        return files
      }).flat(1)
      const newPhotos = el.image ? [{
        file: el.image,
        addtionnalData: { type: "accident-photo" }
      }] : []
      return [...newFiles, ...newPhotos];
    },
  },
};
</script>
