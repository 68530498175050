<template>
  <div id="accident-block" ref="accident-block" v-bind:class="pdfBeingGenerated ? 'p-3' : ''">
    <div class="accident-title bg-primary block p-3">
      <as-title :title="titre" :size="4" />
      <span class="badge bg-default date p-2 ml-auto ">
        {{ date ? $d(date, "day") : "?" }}
        {{ date ? $d(date, "month") : "" }}
        {{ date ? $d(date, "year") : "" }}
      </span>
    </div>
    <div class="accident-header mt-3 grid ml-0 mr-0">
      <div class="accident-header__image-container bg-default col-4">
        <ProgressSpinner class="m-auto" v-if="isBusy" />
        <template v-else>
          <img v-if="image" :src="image" class="grid accident-img m-0" />
          <img v-else :src="defaultImage" class="grid accident-img m-0" />
        </template>
      </div>
      <div class="accident-header__details flex flex-columns col-8 p-0 pl-3">
        <as-block class="bg-tertiary-sliced flex-1 flex flex-columns" gutters="10px">
          <div class="grid">
            <div class="col-12 text-right">
              <img v-if="entreprise.fichierLogo" :src="entreprise.fichierLogo.url" class="flag-img mr-3"
                alt="profile" />
              <img v-if="entreprise.fichierDrapeau" :src="entreprise.fichierDrapeau.url" class="flag-img" />
            </div>
          </div>
          <div class="grid flex-1 align-items-center">
            <div class="col-6 accident-header__details-left">
              <div class="accident-header__details-left-row mb-3 w-100 " v-if="entreprise">
                <span class="title">{{ $t('home.last_accident_location') }}</span>
                <span> {{ entreprise.details.nom }} </span>
              </div>
              <div class="accident-header__details-left-row mb-3 w-100" v-if="declarationAccident?.lieu">
                <span class="title">{{ $t('home.accident_injury_aera') }}</span>
                <span> {{ $t("locations." + declarationAccident?.lieu) }} </span>
              </div>
              <div class="accident-header__details-left-row w-100" v-if="declarationAccident?.categoriePersonne">
                <span class="title">{{ $t('home.accident_category') }}</span>
                <span> {{ $t("categoryPersons." + declarationAccident?.categoriePersonne) }} </span>
              </div>
            </div>
            <div class="col-6 accident-header__details-right">
              <div class="accident-header__details-right-row mb-3 w-100" v-if="declarationAccident?.zoneBlessures">
                <span class="title">{{ $t('home.accident_injury_location') }}</span>
                <span> {{ $t("woundsLocations." + declarationAccident?.zoneBlessures) }} </span>
              </div>
              <div class="accident-header__details-right-row mb-3 w-100" v-if="declarationAccident?.natureBlessures">
                <span class="title">{{ $t('home.accident_injury_nature') }}</span>
                <span> {{ $t("woundsTypes." + declarationAccident?.natureBlessures) }} </span>
              </div>
              <div class="accident-header__details-right-row w-100" v-if="declarationAccident?.codeBarreProduit">
                <span class="title">{{ $t('home.accident_bar_code_product') }}</span>
                <span> {{ declarationAccident?.codeBarreProduit }} </span>
              </div>
            </div>
          </div>
        </as-block>
        <div class="mt-3 flex">
          <span class="badge p-2 mr-2" :style="{ backgroundColor: getAccidentTypeColor(type), 'color': 'white' }">
            {{ getAccidentTypeReducedabel(type) }}
          </span>
          <span class="badge badge-high-severity p-2" v-if="declarationAccident?.highSeverityPotential">
            {{ $t('share_accident.high_gravity_label') }}
          </span>
          <div class="ml-auto">
            <Button v-if="isAdmin && !editAccidentBusy" id="edit-accident" @click="editAccident" icon="pi pi-pencil"
              :title="$t('share_accident.edit')" class="p-button-sm p-button-rounded button-primary ml-auto" />
            &nbsp;
            <Button id="share-accident" @click="shareAccident" icon="pi pi-share-alt"
              :title="$t('share_accident.share')" class="p-button-sm p-button-rounded button-primary ml-auto" />
          </div>
        </div>
      </div>
    </div>
    <as-block class="bg-light mt-3" gutters="20px">
      <as-title :title="$t('home.last_accident_description')" :size="5" />
      <p> {{ description }} </p>
    </as-block>
    <as-block class="bg-light mt-3" gutters="20px">
      <as-title :title="$t('home.last_accident_cause')" :size="5" />
      <p> {{ causes }} </p>
    </as-block>
    <as-block gutters="10px" class="mt-3">
      <as-title :title="$t('home.last_accident_improvements')" :size="5" />
    </as-block>
    <div class="grid">
      <div class="col">
        <div class="flex improvement grid">
          <div class="improvement-container col-6" v-for="(improvement, index) in improvementList" :key="index">
            <span class="improvement-container__header">{{ index + 1 }}</span>
            <as-block class="bg-primary h-100" gutters="20px">
              <p> {{ improvement }} </p>
            </as-block>
          </div>
        </div>
      </div>
    </div>
    <as-block v-if="!pdfBeingGenerated && fichiers.length" gutters="10px" class="mt-3">
      <as-title :title="$t('home.documents')" :size="5" />
    </as-block>
    <div v-if="!pdfBeingGenerated" class="grid">
      <div class="col">
        <div class="flex improvement grid">
          <as-file v-for="fichier in fichiers" :key="fichier.id" class="col-6 header-file__content" :fichier="fichier"
            @onDownload="onDownloadFichier" />
        </div>
        <div v-if="showPdfButton" class="text-right mt-5">
          <Button id="download-pdf" ref="download-pdf" icon="pi pi-download" iconPos="right"
            :label="$t('statistics.download_button')" class="button-default" @click="downloadWithCSS" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';

import AsTitle from "../Elements/AsTitle";
import AsBlock from "../Containers/AsBlock";
import AsFile from "@/components/Elements/AsFile";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import { bufferToBlobUrl } from "../../util";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import apiEventTypes from "../../constantes/apiEventTypes";
import roleMixins from "@/mixins/roleMixins";
import { getAccidentTypeReducedabel, getAccidentTypeColor, } from "../../util";

export default {
  name: "AccidentBlock",
  components: {
    AsTitle,
    AsBlock,
    AsFile,
    ProgressSpinner,
    Button,
  },
  props: {
    editAccidentBusy: Boolean,
    accidentData: {
      titre: String,
      description: String,
      date: [Date, String],
      causes: String,
      improvement: String,
      fichiers: [
        {
          codeIso: String,
          date: String,
          externalId: String,
          id: Number,
          titre: String,
          url: String,
        },
      ],
      entreprise: Object,
      declarationAccident: Object,
    },
    showPdfButton: Boolean,
  },

  watch: {
    accidentData() {
      if (!this.image) {
        this.onLoadImage(this.accidentData);
      }
    },
  },
  beforeMount() {
    this.onLoadImage(this.accidentData);
  },
  emits: ["editAccident"],
  mixins: [roleMixins, fichiersServiceApi],
  methods: {
    onDownloadFichier(externalId, titre) {
      this.downloadFile(externalId, titre)
    },

    async onLoadImage(value) {
      if (value.image && value.image.externalId != null) {
        this.isBusy = true;
        try {
          const fichier = await this.downloadFile(value.image.externalId);
          this.image = bufferToBlobUrl(fichier);
        } catch (error) {
          console.log(error)
        }
      }
      this.isBusy = false;
    },
    downloadWithCSS() {
      this.pdfBeingGenerated = true;
      const doc = new jsPDF({ unit: "px" });
      const accidentBlock = this.$refs["accident-block"];
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = doc.internal.pageSize.getHeight();
      accidentBlock.style.width = "1200px";
      accidentBlock.style.height = "1800px";
      setTimeout(() => {
        html2canvas(accidentBlock, { scale: 3 }).then((canvas) => {
          const img = canvas.toDataURL("image/jpeg");
          doc.addImage(img, "JPEG", 5, 0, pdfWidth - 10, pdfHeight);
          doc.save(`${this.accidentData.titre}.pdf`);
          this.pdfBeingGenerated = false;
        });
        accidentBlock.style.width = "inherit";
        accidentBlock.style.height = "inherit";
      }, 100);
    },
    editAccident() {
      this.$emit("editAccident", this.accidentData);
    },
    shareAccident() {
      const key = "share-accidents";
      const splittedHref = window.location.href.split(key);
      const lLink = `${splittedHref[0]}${key}/${this.accidentData.id}`;
      navigator.clipboard.writeText(lLink);
      document.dispatchEvent(
        new CustomEvent(apiEventTypes.SUCCESS, {
          detail: {
            title: this.$t("share_accident.share_copy_title"),
            message: this.$t("share_accident.share_copy_content"),
          },
        })
      );
    },
    getAccidentTypeColor(number) {
      return getAccidentTypeColor(number, this.$t)
    },
    getAccidentTypeReducedabel(number) {
      return getAccidentTypeReducedabel(number)
    },
  },

  data() {
    const {
      titre,
      date,
      description,
      causes,
      mesures,
      fichiers,
      entreprise,
      declarationAccident
    } =
      this.accidentData;
    return {
      isBusy: false,
      titre,
      entreprise,
      declarationAccident,
      date,
      description,
      causes,
      improvementList:
        mesures.substr(0, 1) === "[" ? JSON.parse(mesures) : [mesures],
      fichiers,
      image: undefined,
      defaultImage: require("../../assets/images/accident.jpeg"),
      pdfBeingGenerated: false,
    };
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";
@import "../../assets/styles/sizing.scss";

.p-carousel-item {
  max-height: 0;
}

.p-carousel-item-active {
  max-height: 100% !important;
}

#accident-block {

  .flex-columns {
    flex-direction: column !important;
  }

  .flex-1 {
    flex: 1 !important;
  }

  .accident-title {
    display: flex !important;
    align-items: center;

    h4 {
      color: white !important;
    }

    .badge {
      font-weight: bold;
      border-radius: 10px;
    }
  }

  .accident-header {
    &__image-container {
      display: flex !important;
      min-height: 300px;
      max-height: 300px;
      border-radius: 20px;
      padding: 0;
      overflow: hidden;

      img {
        background-color: white;
        width: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }

    &__details {
      min-height: 300px;
      max-height: 300px;

      &-left,
      &-right {
        &-row:last-child {
          margin-bottom: 0 !important;
        }
      }

      .flag-img {
        width: 50px;
        height: 50px;
      }

      span.title {
        font-weight: bold;
        margin-right: 5px;
      }
    }

    .badge-high-severity {
      background-color: #bf2d0f;
      color: white;
      top: 10px;
      right: 10px;
    }
  }

  .badge {
    border-radius: 0.375rem;
  }


  .location {
    padding-left: 10px;
    align-self: center;
  }

  .date-header {
    &__date {
      flex: 1;
      display: block;
      padding: 20px;
      text-align: left;
      justify-content: center;

      .day {
        font-size: 100px;
        font-weight: bold;
        line-height: 100px;
        letter-spacing: -10px;
      }

      .month {
        font-size: 50px;
        margin-left: 8px;
        vertical-align: top;
        line-height: 40px;
        text-transform: uppercase;
      }
    }

    &__image {
      img {
        margin-left: auto;
        max-width: 100%;
        max-height: 450px;
      }
    }
  }

  .improvement {
    flex-wrap: wrap;

    &-container {
      position: relative;

      &__header {
        position: absolute;
        top: -5px;
        left: -5px;
        width: 30px;
        height: 30px;
        border-radius: 25px;
        margin: auto;
        background: $dark_font;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-weight: bold;
      }
    }
  }


  @media screen and (max-width: 991px) {
    .accident-header {
      >div {
        width: 100%;
      }

      &__details {
        padding-left: 0 !important;
        margin-top: 20px !important;
      }
    }

    .accident-img {
      border-radius: 20px;
    }

    .date-header {
      &__image {
        img {
          border-radius: 5px;
          min-height: auto;
        }
      }
    }
  }
}

.declaration-header {

  h5.title {
    padding: 0;
  }
}

#edit-accident-spinner.p-progress-spinner {
  height: 30px;
}
</style>
