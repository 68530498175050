<template>
  <div class="language-selector">
    <Dropdown
      v-model="selectedLocale"
      :options="availableLocales"
      optionLabel="locale"
      placeholder="Select a language"
      @change="changeLocale($event)"
    >
      <template #value="slotProps">
        <div v-if="slotProps.value" class="flex-1">
          <img
						v-if="slotProps.value"
            class="flag"
            :key="`locale-${slotProps.value.locale}`"
            :src="getFlag(slotProps.value.locale)"
          />
        </div>
        <span v-else>
          {{ slotProps.placeholder }}
        </span>
      </template>
      <template #option="slotProps">
        <div class="flex-1">
          <img
            class="flag"
            :key="`locale-${slotProps.option}`"
            :src="getFlag(slotProps.option)"
          />
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import changeLocalesMixins from "../../mixins/changeLocalesMixins";
import Dropdown from "primevue/dropdown";
export default {
  name: "AsLanguageSelector",
  components: {
    Dropdown,
  },
  computed: {
    currentFlag() {
      return require(`../../assets/images/flags/${this.$t("flag_code")}.svg`);
    },
    availableLocales() {
      return this.$i18n.availableLocales.filter((el) => {
        return el.length === 2;
      });
    },
  },
  data() {
    return {
      selectedLocale: this.$i18n.locale,
    };
  },
  mixins: [changeLocalesMixins],
  methods: {
    getFlag(locale) {
      const flagFileName = locale !== undefined ? locale : this.$t("flag_code", locale);
      return require(`../../assets/images/flags/${flagFileName}.svg`);
    },
    changeLocale(locale) {
      this.changeCurrentLocale(locale.value);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/colors.scss";

.language-selector {
  background-color: $light_bg;
  box-sizing: content-box;
  .flag {
    cursor: pointer;
    width: auto;
    height: 24px;
    align-self: center;
  }
  .selector {
    display: flex;
    align-items: center;
    .flag {
      margin-left: 20px;
    }
  }
}
</style>
