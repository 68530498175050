<template>
  <div id="share-accident">
    <as-container v-if="isAdmin && declarations.length">
      <as-block :shadow="true" gutters="0px">
        <Message v-if="declarations.length === 1" severity="warn" :closable="false">
          <div class="flex p-justify-content-start align-items-center">
            <div class="ml-2 mr-4">
              {{
                $t("share_accident.declaration_wait_warn_message", {
                  number: declarations.length,
                })
              }}
            </div>
            <div>
              <Button v-if="!showDeclarations" :label="$t('share_accident.show_declarations_button')"
                class="button-default" @click="showDeclarations = true" />
            </div>
          </div>
        </Message>
        <Message v-else-if="declarations.length > 1" severity="warn" :closable="false">
          <div class="flex p-justify-content-start align-items-center">
            <div class="ml-2 mr-4">
              {{
                $t("share_accident.declaration_wait_warn_message_plural", {
                  number: declarations.length,
                })
              }}
            </div>
            <div>
              <Button v-if="!showDeclarations" :label="$t('share_accident.show_declarations_button')"
                class="button-default" @click="showDeclarations = true" />
            </div>
          </div>
        </Message>
        <list-declarations :declarations="declarations" v-if="showDeclarations"
          @onShowValidateModal="onShowValidateDeclarationModal" @onShowDeleteModal="onShowDeleteDeclarationModal" />
      </as-block>
    </as-container>
    <as-container gutters="0">
      <div class="grid grid-nogutter">
        <div class="flex w-100">
          <as-title :title="$t('home.last_accident_title')" :size="1"></as-title>
          <div class="ml-auto" v-if="isSafetyLeader || isAdmin">
            <Button :label="$t('share_accident.declare_new')" class="button-default" @click="onShowDeclarationModal" />
          </div>
        </div>
      </div>
      <br />
      <as-block shadow gutters="20px">
        <list-accidents :accidents="accidents" @onSelectAccident="displayAccidentInfoModal" />
      </as-block>
    </as-container>
  </div>
  <as-modal :isVisible="showDeclarationModal">
    <declaration-form v-if="showDeclarationModal" :isSending="isSending" @onClose="onHideDeclarationModal"
      @onSubmit="sendAccidentDeclaration" />
  </as-modal>
  <as-modal :isVisible="showValidateDeclarationModal" class="validate-declaration-modal">
    <validate-declaration-form v-if="declarationToValidate" :accidentToEdit="accidentToEdit"
      :declaration="declarationToValidate" :isClosed="showValidateDeclarationModal" :isSending="isSending"
      @onClose="onHideValidateDeclarationModal" @onSubmit="validateDeclaration" />
  </as-modal>
  <as-modal :isVisible="showAccidentInfoModal" isCloseable @onClose="
    showAccidentInfoModal = false;
  accidentInfo = null;
  ">
    <accident-block v-if="accidentInfo" :accidentData="accidentInfo" :editAccidentBusy="editAccidentBusy"
      @editAccident="editAccident($event)" :showPdfButton="true" />
  </as-modal>
  <as-confirm-modal :isVisible="showDeleteDeclarationModal" :modalMessage="$t('confirm_modal.delete_message')"
    @onConfirm="deleteDeclarationConfirm" @onCancel="deleteDeclarationCancel" />
</template>

<script>
// @ is an alias to /src
import AsTitle from "@/components/Elements/AsTitle.vue";
import AsContainerBlock from "@/components/Containers/AsContainerBlock.vue";
import AsContainer from "@/components/Containers/AsContainer.vue";
import AsBlock from "@/components/Containers/AsBlock.vue";
import AsModal from "@/components/Modals/AsModal.vue";
import AccidentBlock from "../components/ShareAccident/AccidentBlock.vue";
import ListAccidents from "../components/ShareAccident/ListAccidents";
import ListDeclarations from "../components/ShareAccident/ListDeclarations";
import DeclarationForm from "../components/Forms/DeclarationForm";
import ValidateDeclarationForm from "../components/Forms/ValidateDeclarationForm";
import Button from "primevue/button";
import Message from "primevue/message";
import Carousel from "primevue/carousel";
// import Carousel from 'primevue/carousel';
import accidentsServiceApi from "../services/accidentsServiceApi";
import declarationsServiceApi from "../services/declarationsServiceApi";
import localeChangeDetectionMixin from "../mixins/localeChangeDetectionMixins";
import roleMixins from "../mixins/roleMixins";
import apiEventTypes from "@/constantes/apiEventTypes";
import fichiersServiceApi from "../services/fichiersServiceApi";
import { mapGetters } from "vuex";
import AsConfirmModal from "@/components/Modals/AsConfirmModal.vue";
import { mapAccidentData } from "../util";


export default {
  name: "ShareAccident",
  components: {
    AsTitle,
    AsContainerBlock,
    AsContainer,
    AsBlock,
    AsModal,
    Button,
    Message,
    Carousel,
    AccidentBlock,
    ListAccidents,
    ListDeclarations,
    DeclarationForm,
    ValidateDeclarationForm,
    AsConfirmModal,
  },
  data() {
    return {
      currentPage: 0,
      numberPerPage: 10,
      skeletonAccidents: [],
      accidents: [],
      totalRecords: 0,
      isAccidentsBusy: false,
      isLastAccidentBusy: false,
      lastAccident: undefined,
      showDeclarationModal: false,
      declarations: [],
      showDeclarations: false,
      declarationToValidate: undefined,
      declarationToValidateId: NaN,
      showValidateDeclarationModal: false,
      accidentInfo: undefined,
      showAccidentInfoModal: false,
      isSending: false,
      targetAccident: undefined,
      accidentToEdit: undefined,
      editAccidentBusy: false,
      showDeleteDeclarationModal: false,
      declarationToDeleteId: NaN,
      sseObservable: undefined,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      config: "getConfig",
    }),
  },
  mixins: [localeChangeDetectionMixin, roleMixins, fichiersServiceApi],
  beforeMount() {
    this.skeletonAccidents = new Array(this.numberPerPage);
    this.reloadContents();
    if (this.$route.params.id) {
      this.getAccidentById(this.$route.params.id, true);
    }
  },
  methods: {
    async reloadContents() {
      if (this.isAdmin) {
        this.declarations = [];
        this.getDeclarations();
      }
      this.filteredThreeLastAccidents = [];
      await this.getAccidents();
    },
    onLocaleChange() {
      this.reloadContents();
      this.$emit("onLocaleChange");
    },
    onShowDeclarationModal() {
      this.showDeclarationModal = true;
    },
    onHideDeclarationModal() {
      this.showDeclarationModal = false;
    },
    getDeclarationToValidate() {
      this.declarationToValidate = this.declarations.find((el) => {
        return el.id === this.declarationToValidateId;
      });
    },
    async getAccidents() {
      this.isAccidentsBusy = true;
      const response = await accidentsServiceApi.getAccidents();
      this.accidents = response;
      this.totalRecords = response.length;
      this.isAccidentsBusy = false;
      const sharedAccident = this.accidents.find(
        (e) => e.id === +this.targetAccident
      );
      if (sharedAccident) {
        this.displayAccidentInfoModal(sharedAccident);
      }
      this.isAccidentsBusy = false;
    },
    getAccidentById(id, displayModal) {
      this.editAccidentBusy = true;
      accidentsServiceApi
        .getAccidentById(id)
        .then((response) => {
          this.accidentInfo = null;
          this.editAccidentBusy = false;
          this.showAccidentInfoModal = false;
          if (displayModal) {
            this.showValidateDeclarationModal = false;
            this.displayAccidentInfoModal(response);
          } else {
            this.showValidateDeclarationModal = true;
            this.accidentToEdit = response;
            this.declarationToValidate =
              this.accidentToEdit.declarationAccident;
          }
        })
        .catch(() => { });
    },
    async sendAccidentDeclaration(declaration) {
      this.isSending = true;
      if (this.isSafetyLeader || this.isAdmin) {
        declarationsServiceApi
          .saveDeclarations(declaration)
          .then((savedDeclaration) => {
            this._uploadShareAccidentFiles(declaration, savedDeclaration.id, true)
            this.onHideDeclarationModal();
            this.reloadContents();
            this.isSending = false;
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_SUCCESS, {
                detail: {
                  title: this.$t("popup_messages.success_title"),
                  message: this.$t(
                    "popup_messages.success_message_declaration"
                  ),
                },
              })
            );
          })
          .catch(() => {
            this.isSending = false;
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.ERROR, {
                detail: {
                  title: this.$t("popup_messages.default_error_title"),
                  message: this.$t("popup_messages.default_error_message"),
                },
              })
            );
          });
      }
    },
    getDeclarations() {
      if (this.isAdmin) {
        declarationsServiceApi.getDeclarations(0, 1000).then((result) => {
          this.declarations = result.data;
          this.declarations.sort((a, b) => {
            const aDate = new Date(`${a.date} ${a.heure}`);
            const bDate = new Date(`${b.date} ${b.heure}`);
            return aDate.getTime() - bDate.getTime();
          });
        });
      }
    },
    onShowValidateDeclarationModal(idDeclaration) {
      this.declarationToValidate = undefined;
      this.accidentToEdit = undefined;
      this.declarationToValidateId = idDeclaration;
      this.showValidateDeclarationModal = true;
      this.getDeclarationToValidate();
    },
    onHideValidateDeclarationModal() {
      this.declarationToValidate = undefined;
      this.accidentToEdit = undefined;
      this.declarationToValidateId = NaN;
      this.showValidateDeclarationModal = false;
    },
    async validateDeclaration(accident) {
      if (this.isAdmin) {

        if (this.accidentToEdit) {
          accident.id = this.accidentToEdit.id;
        }

        accidentsServiceApi
          .saveAccident(accident, this.declarationToValidate.id)
          .then((savedAccident) => {
            this._uploadShareAccidentFiles(accident, savedAccident.id)
            this.accidentToEdit = undefined;
            this.declarationToValidate = undefined;
            this.declarationToValidateId = NaN;
            this.showValidateDeclarationModal = false;

            this.reloadContents();
            this.isSending = false;
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.API_SUCCESS, {
                detail: {
                  title: this.$t("popup_messages.success_title"),
                  message: this.$t("popup_messages.success_message"),
                },
              })
            );
          })
          .catch(() => {
            this.isSending = false;
            document.dispatchEvent(
              new CustomEvent(apiEventTypes.ERROR, {
                detail: {
                  title: this.$t("popup_messages.default_error_title"),
                  message: this.$t("popup_messages.default_error_message"),
                },
              })
            );
          });
      }
    },
    displayAccidentInfoModal(accident) {
      this.accidentInfo = mapAccidentData(accident, this.$i18n.locale);
      this.showAccidentInfoModal = true;
    },
    editAccident(accident) {
      this.getAccidentById(accident.id, false);
    },

    onShowDeleteDeclarationModal(idDeclaration) {
      this.declarationToDeleteId = idDeclaration;
      this.showDeleteDeclarationModal = true;
    },
    deleteDeclarationConfirm() {
      this.deleteDeclaration(this.declarationToDeleteId);
    },
    deleteDeclarationCancel() {
      this.showDeleteDeclarationModal = false;
    },

    async deleteDeclaration(declarationId) {
      this.isSending = true;
      const successMessage = () => {
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.API_SUCCESS, {
            detail: {
              title: this.$t("popup_messages.success_title"),
              message: this.$t("popup_messages.success_delete_message"),
            },
          })
        );
      };
      const errorMessage = () => {
        document.dispatchEvent(
          new CustomEvent(apiEventTypes.ERROR, {
            detail: {
              title: this.$t("popup_messages.default_error_title"),
              message: this.$t("popup_messages.default_error_message"),
            },
          })
        );
      };
      if (this.isSafetyLeader || this.isAdmin) {
        declarationsServiceApi
          .deleteDeclaration(declarationId)
          .then((result) => {
            if (result) {
              this.reloadContents();
              successMessage();
            } else {
              errorMessage();
            }
          })
          .catch(() => {
            errorMessage();
          })
          .finally(() => {
            this.isSending = false;
            this.showDeleteDeclarationModal = false;
          });
      }
    },

    async _uploadShareAccidentFiles(el, entityId, isDeclaration = false) {
      const files = this._getFilesToUpload(el, isDeclaration)
      this.uploadFiles(files, isDeclaration ? "declaration-accident" : "accident-detail", entityId, +this.user.idLdap, () => {
        this.reloadContents()
      })
    },

    _getFilesToUpload(el, isDeclaration) {
      if (isDeclaration) { return el.filesToUpload }

      const newFiles = el.details.filter(e => e.filesToUpload.length).map(e => {
        const files = e.filesToUpload
        files.forEach(file => {
          file['addtionnalData'] = {
            type: "accident-files",
            idLangue: e.idLangue
          }
        })
        return files
      }).flat(1)
      const newPhotos = el.image ? [{
        file: el.image,
        addtionnalData: { type: "accident-photo" }
      }] : []
      return [...newFiles, ...newPhotos];
    },
  },
};
</script>

<style lang="scss">
#share-accident {
  .p-message {
    margin: 0;
  }

  .actions {
    margin-top: 20px;
  }
}
</style>
