<template>
  <form id="validate-declaration-form" @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
      <div class="col-12 flex align-items declaration-header">
        <div></div>
        <as-title class="title" :title="declaration.type === 5
          ? $t('validate_declaration_form.title_near_miss')
          : $t('validate_declaration_form.title')
          " :size="5" />
      </div>
    </div>
    <div class="grid">
      <div class="col-12 flex align-items declaration-header">
        <span :style="{ backgroundColor: getAccidentTypeColor(declaration.type), 'color': 'white', borderRadius: '15px'}" class="p-3" > 
                {{ getAccidentTypeLabel(declaration.type) }}
        </span>
        <img v-if="declaration.highSeverityPotential" 
            :src="highSeverityPotentialImg" class="ml-3" alt="accident-img" 
            v-tooltip.top="$t('declaration_accident_form.high_severity_potential')"/>
      </div>
    </div>
    <div class="grid content" v-if="declaration">
      <div class="col-12 md:col-6" v-if="!accidentToEdit">
        <div class="p-fluid grid">
          <div class="col-12 md:col-6">
            <label>{{ $t("validate_declaration_form.enterprise") }}</label>
            <p>
              {{
                declaration.entreprise
                ? declaration.entreprise.details.nom
                : ""
              }}
            </p>
          </div>
          <div class="col-12 md:col-6">
            <label>{{ $t("validate_declaration_form.date_field") }}</label>
            <p>
              {{ formatDate(declaration.date) + " " + declaration.heure }}
            </p>
          </div>
          <div class="col-12">
            <label>{{ $t("validate_declaration_form.location_field") }}</label>
            <p>{{ $t("locations." + declaration.lieu) }}</p>
          </div>
          <div class="col-12">
            <label>{{ $t("validate_declaration_form.category_field") }}</label>
            <p>{{ $t("categoryPersons." + declaration.categoriePersonne) }}</p>
          </div>
          <div class="col-12">
            <label>{{
              $t("validate_declaration_form.description_field")
            }}</label>
            <p class="desc">{{ declaration.description }}</p>
          </div>
          <div class="col-12">
            <label>{{
              $t("validate_declaration_form.injury_area_field")
            }}</label>
            <p>
              {{
                declaration.zoneBlessures
                ? $t("woundsLocations." + declaration.zoneBlessures)
                : ""
              }}
            </p>
          </div>
          <div class="col-12">
            <label>{{
              $t("validate_declaration_form.nature_injury_field")
            }}</label>
            <p>
              {{
                declaration.natureBlessures
                ? $t("woundsTypes." + declaration.natureBlessures)
                : ""
              }}
            </p>
          </div>
          <div class="col-12">
            <label>{{ $t("validate_declaration_form.barcode_field") }}</label>
            <p>{{ declaration.codeBarreProduit }}</p>
          </div>
          <div class="col-12">
            <label>{{ $t("validate_declaration_form.action_field") }}</label>
            <p class="desc">{{ declaration.actionsMiseEnOeuvre }}</p>
          </div>
          <div class="col-12">
            <label>{{
              $t("validate_declaration_form.consequences_field")
            }}</label>
            <p class="desc">{{ declaration.consequenceAccidente }}</p>
          </div>
          <div class="col-12" v-if="declaration.fichiers">
            <label>{{ $t("validate_declaration_form.files_field") }}</label>
            <div class="grid">
              <as-file v-for="(fichier, index) in declaration.fichiers" :key="index" :fichier="fichier"
                @onDownload="onDownloadFichier" class="col-6" />
              <div v-if="declaration.fichiers.length === 0" class="col-12">
                {{ $t("validate_declaration_form.no_files") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" v-bind:class="[accidentToEdit ? '' : 'md:col-6']">
        <div class="flex align-items">
          <img class="image-accident" v-if="image" :src="image" />
          <FileUpload class="button-default file-upload" mode="basic" :customUpload="true" @select="onSelectedImage"
            accept="image/*" :chooseLabel="$t('agenda_form.choose_img_button')" :multiple="false" />
        </div>
        <TabView>
          <TabPanel v-for="(detail, index) in accident.details" :key="index">
            <template #header>
              <div>{{ detail.langue.libelle }}</div>
              <div v-if="subFormsInvalid[detail.langue.id]" class="error ml-1 pi pi-exclamation-circle"></div>
            </template>
            <div>
              <Button v-if="detail.langue.codeIso !== selectedLocale" :label="$t('form.translate')" class="button-primary"
                @click="translateTo(detail.langue)" />
              <ProgressSpinner strokeWidth="5" v-if="beingTranslated" />
              <br />
              <br /> 
              <accident-form :ref="'detail-' + detail.idLangue" v-model="accident.details[index]" :submitted="submitted"
                :files="files" :mesureCount="mesureCount" :isEdit="accidentToEdit ? true : false" :declaration="accidentToEdit
                  ? accidentToEdit.declarationAccident
                  : undefined
                  " @incrementMesure="incrementMesure" @onDeclarationDataChange="onDeclarationDataChange" />
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
    <div class="grid fixed-bottom">
      <div class="col flex justify-content-end">
        <Button :label="$t('form.cancel_button')" class="button-default" @click="onClose" />
        <Button v-show="declaration" type="submit" :label="$t('form.submit_button')" class="button-primary ml-2" />
      </div>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";

import AsTitle from "../Elements/AsTitle.vue";
import AsFile from "../Elements/AsFile.vue";
import AccidentForm from "./AccidentForm.vue";

import Button from "primevue/button";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ProgressSpinner from "primevue/progressspinner";
import FileUpload from "primevue/fileupload";

import { mapGetters } from "vuex";
import fichiersServiceApi from "../../services/fichiersServiceApi";
import { translate } from "../../services/googleTranslateService";
import moment from "moment";
import { getAccidentTypeLabel, getAccidentTypeColor } from "../../util";

export default {
  name: "ValidateDeclarationForm",
  components: {
    AsTitle,
    AsFile,
    Button,
    AccidentForm,
    TabView,
    TabPanel,
    ProgressSpinner,
    FileUpload,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    isClosed: Boolean,
    isSending: Boolean,
    declaration: Object,
    accidentToEdit: Object,
  },
  data() {
    return {
      submitted: false,
      accident: {
        date: this.declaration.date + " " + this.declaration.heure,
        idEntreprise: this.declaration.idEntreprise,
        details: [],
        image: undefined,
      },
      files: [],
      subFormsInvalid: {},
      selectedLocale: this.$i18n.locale,
      mesureCount: 1,
      beingTranslated: false,
      fileToUpload: undefined,
      image: undefined,
      highSeverityPotentialImg: require("../../assets/images/high-security-potential-icon.png"),
      editedDeclarationData: undefined,
    };
  },
  mixins: [fichiersServiceApi],
  watch: {
    driveConfig(newValue) {
      if (newValue) {
        this.getFiles().then((result) => {
          this.files = result;
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      languages: "getLanguages",
      driveConfig: "getConfig",
    }),
  },
  emits: ["onClose", "onSubmit"],
  beforeMount() {
    this.getFiles().then((result) => {
      this.files = result;
    });

    const languages = [...this.languages];
    const indexOf = languages.findIndex((e) => e.codeIso === "fr");
    const selectedLang = languages.splice(indexOf, 1);
    [...selectedLang, ...languages].forEach((lang) => {
      this.subFormsInvalid[lang.id] = false;
      const detail =
        this.accidentToEdit && this.accidentToEdit.details
          ? this.accidentToEdit.details.find((e) => e.idLangue === lang.id)
          : undefined;
      if (detail) {
        if (!detail.fichiers) {
          detail.fichiers = [];
        }
        if (!detail.filesToUpload) {
          detail.filesToUpload = [];
        }
      }
      this.accident.details.push(
        detail
          ? detail
          : {
            idLangue: lang.id,
            langue: lang,
            titre: "",
            description: "",
            causes: "",
            mesures: "",
            fichiers: [],
            filesToUpload: [],
          }
      );
      this.mesureCount = detail ? JSON.parse(detail.mesures).length : 1;
    });
  },
  methods: {
    handleSubmit(isFormValid) {
      let canSubmit = true;
      this.accident.details.forEach((el) => {
        this.subFormsInvalid[el.idLangue] =
          this.$refs["detail-" + el.idLangue][0].v$.$invalid;
        if (
          this.$refs["detail-" + el.idLangue][0].v$.$invalid &&
          this.$refs["detail-" + el.idLangue][0].v$.$invalid === true
        ) {
          canSubmit = false;
        }
      });
      isFormValid = canSubmit;
      if (this.fileToUpload) {
        this.accident.image = this.fileToUpload;
      }

      this.submitted = true;
      if (isFormValid === false) {
        return;
      }
      if (this.editedDeclarationData) {
        this.accident.editedDeclarationData = this.editedDeclarationData;
        this.accident.date = this.editedDeclarationData.date;
      }
      this.$emit("onSubmit", this.accident);
    },
    onClose() {
      this.$emit("onClose");
    },
    async translateTo(target) {
      this.beingTranslated = true;
      const primaryLangueAccident = this.accident.details.find(
        (e) => e.langue.codeIso !== target.codeIso
      );
      const mesures = primaryLangueAccident.mesures
        ? JSON.parse(primaryLangueAccident.mesures)
        : [];
      const stringsToTranslate = [
        primaryLangueAccident.titre,
        primaryLangueAccident.description,
        primaryLangueAccident.causes,
        ...mesures,
      ];

      const translatedData = await translate(
        stringsToTranslate,
        target.codeIso
      );

      if (stringsToTranslate.length === translatedData.length) {
        const indexOfDetails = this.accident.details.findIndex(
          (e) => e.langue.codeIso === target.codeIso
        );
        const details = this.accident.details[indexOfDetails];
        details.titre = translatedData.splice(0, 1)[0];
        details.description = translatedData.splice(0, 1)[0];
        details.causes = translatedData.splice(0, 1)[0];
        details.mesures = JSON.stringify(translatedData);
      }
      this.beingTranslated = false;
    },
    async onDownloadFichier(externalId, titre) {
      this.downloadFile(externalId, titre);
    },
    incrementMesure() {
      this.mesureCount++;
    },
    onSelectedImage(event) {
      this.fileToUpload = event.files[0];
      this.image = event.files[0].objectURL;
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    onDeclarationDataChange(data) {
      this.editedDeclarationData = data;
    },
    getAccidentTypeLabel(number) {
        return getAccidentTypeLabel(number, this.$t)
    },
    getAccidentTypeColor(number) {
        return getAccidentTypeColor(number)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/colors.scss";

.image-accident {
  max-height: 100px;
}

form {
  position: relative;

  .help button {
    height: 100%;
  }

  .fixed-bottom {
    position: sticky;
    width: 100%;
    box-sizing: content-box;
    background-color: white;
    margin-left: -40px;
    margin-right: -40px;
    border-top: solid 1px $default_color;
    padding-left: 40px;
    padding-right: 40px;
    bottom: -40px;
    left: 0;
    right: 0;
  }

  .title {
    padding-top: 0;
    padding-bottom: 30px;
  }

  label {
    font-weight: bold;
    text-decoration: underline;

    &::after {
      content: " :";
    }
  }

  &#validate-declaration-form {
    .desc {
      white-space: break-spaces;
    }

    .p-progress-spinner {
      height: 30px !important;
    }
  }

  .error {
    color: $error_color;
  }
}

.declaration-header {

  h5.title {
    padding: 0;
  }
}
</style>
