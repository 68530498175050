import { Buffer } from "buffer";

export const ellipsis = (value, maxsize = 30) => {
  if (value === null || value === undefined) {
    return "";
  }
  if (value.length <= maxsize + 3) {
    return value;
  }
  value = value.substring(0, maxsize);
  let last = value.lastIndexOf(" ");
  value = value.substring(0, last);
  return value + "...";
};

export const bufferToBlobUrl = (buffer, mimeType) => {
  let blob = new Blob([buffer], { type: mimeType });
  return URL.createObjectURL(blob);
};

export const bufferToJpegBase64 = (buffer) => {
  let b64 = Buffer.from(buffer)
    .toString("base64")
    .replace("dataimage/jpegbase64", "");
  return `data:image/jpeg;base64,${b64}`;
};

export const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, size + i));
  }
  return result;
};

export const isMobile = (opts) => {
  const mobileRE = /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
  const notMobileRE = /CrOS/
  const tabletRE = /android|ipad|playbook|silk/i
  if (!opts) opts = {}
  let ua = opts.ua
  if (!ua && typeof navigator !== 'undefined') ua = navigator.userAgent
  if (ua && ua.headers && typeof ua.headers['user-agent'] === 'string') {
    ua = ua.headers['user-agent']
  }
  if (typeof ua !== 'string') return false

  let result =
    (mobileRE.test(ua) && !notMobileRE.test(ua)) ||
    (!!opts.tablet && tabletRE.test(ua))

  if (
    !result &&
    opts.tablet &&
    opts.featureDetect &&
    navigator &&
    navigator.maxTouchPoints > 1 &&
    ua.indexOf('Macintosh') !== -1 &&
    ua.indexOf('Safari') !== -1
  ) {
    result = true
  }

  return result
}

export const getAccidentTypeLabel = (number, t) => {
  switch (number) {
    case 2:
      return t(`accident_type.TF2`);
    case 3:
      return t(`accident_type.TF3`);
    case 4:
      return t(`accident_type.TF4`);
    case 5:
      return t(`accident_type.TF5`);
    case 6:
      return t(`accident_type.CSI`);
    default:
      return t(`accident_type.TF1`);
  }
}
export const getAccidentTypeReducedabel = (number) => {
  switch (number) {
    case 2:
      return 'TF2'
    case 3:
      return 'TF3'
    case 4:
      return 'TF4'
    case 5:
      return 'TF5'
    case 6:
      return 'CSI'
    default:
      return 'TF1'
  }
}

export const getAccidentTypeColor = (number) => {
  switch (number) {
    case 2:
      return '#1db5c6';
    case 3:
      return '#0b88b5';
    case 4:
      return '#615e9c';
    case 5:
      return '#0b457a';
    case 6:
      return '#9fc5e8';
    default:
      return '#bf2d0f';
  }
}

export const mapAccidentData = (accident, locale) => {
  const detail = accident.details.find(
    (d) => d.langue.codeIso === locale
  );
  const data = {
    titre: detail ? detail.titre : "",
    causes: detail ? detail.causes : "",
    date: accident.date,
    id: accident.id,
    mesures: detail ? detail.mesures : "",
    description: detail ? detail.description : "",
    fichiers: detail ? detail.fichiers : [],
    image: accident.image,
    entreprise: accident.entreprise,
    declarationAccident: accident.declarationAccident,
  };
  return data
}

export default {
  ellipsis,
  bufferToBlobUrl,
  bufferToJpegBase64,
  chunkArray,
  getAccidentTypeLabel,
  getAccidentTypeReducedabel,
  getAccidentTypeColor,
  mapAccidentData
};
